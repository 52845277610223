import React from "react";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

import NProgressWrapper from "@components/common/NProgressWrapper";

import { customFetch } from "../../../helpers";

import "./Login.css";

export default function LoginWithSSO() {
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();
    
    const [loading, setLoading] = React.useState<boolean>(false);

    const setCheckingCustomerAccountRequest = React.useCallback(
        async (email: string) => {
            setLoading(true);
            try {
                const response = await customFetch(
                    `${process.env.REACT_APP_ADMIN_ACCESS}/customerAccount`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                            authorization: `Bearer ${keycloak.token}`,
                        },
                        body: `email=${email}`,
                    }
                );
                const data = await response.json();
                if (data.data.email !== "" && !data.error) {
                    if (data.data.etataccount === "Activer") {
                        const {
                            data: {
                                uidentitie,
                                firstname,
                                name,
                                uuidprofile,
                                email,
                                secondarycolor,
                                maincolor,
                            },
                        } = data;
                        localStorage.setItem("Uid_Entitie_User", uidentitie);
                        localStorage.setItem("firstName", firstname);
                        localStorage.setItem("lastName", name);
                        localStorage.setItem("uuidprofile", uuidprofile);
                        localStorage.setItem("email", email);
                        localStorage.setItem("secondaryColor", secondarycolor);
                        localStorage.setItem("mainColor", maincolor);
                    } else {
                        localStorage.setItem("email", data.data.email);
                    }
                }
                navigate("/");
            } catch (error) {
                console.log("Error:", error);
            } finally {
                setLoading(false);
            }
        },
        [keycloak.token, navigate]
    );

    React.useEffect(() => {
        const uidEntity = localStorage.getItem("Uid_Entitie_User");

        if (uidEntity !== null && keycloak.authenticated === true) {
            setCheckingCustomerAccountRequest(keycloak.tokenParsed?.email);
        } else {
            keycloak.logout();
        }
    }, [keycloak, setCheckingCustomerAccountRequest]);

    return (
        <Container fluid={true} className="p-0 m-0 overflow-hidden">
            <NProgressWrapper isLoading={loading} />
        </Container>
    );
}
