import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSnapshot } from "valtio";
import { useKeycloak } from "@react-keycloak/web";

import { store, setNotAuthorization } from "../../components/Header/store";

import { PortalCard } from "./PortalCard";
import NotAuthorizationPage from "@components/NotAuthorizationPage";
import { SpinnerWrapper } from "@components/common/SpinnerWrapper";

import "./index.css";

export function Home() {
    const { t } = useTranslation();

    const { keycloak } = useKeycloak();

    const [notAuthorizationIsLoading, setNotAuthorizationIsLoading] =
        useState<boolean>(true);
    const uidEntity = localStorage.getItem("uuidprofile");
    const { mainColor, notAuthorization } = useSnapshot(store);

    async function fetchData() {
        const response = await fetch(
            `${process.env.REACT_APP_ADMIN_ACCESS}/getAutorisationProfileEntity?uuidEntity=${uidEntity}`,
            { headers: { authorization: `Bearer ${keycloak.token}` } }
        );
        const data = await response.json();
        return data;
    }

    const { isLoading, data } = useQuery("myData", fetchData);
    const cardsData: any = {
        firstContainer: [
            {
                id: "Reporting-1",
                uuid: "78201ecc-a821-496d-a18f-dbc5f4ef1ad5",
                title: "Reporting",
                img: "Reporting.png",
                description: "Statistics and KPIS",
                link: `${process.env.REACT_APP_LINK_URL_REPORTING}`,
            },
            {
                id: "Catalogue-1",
                uuid: "5090fe0f-69fc-9ff9-699d-d9a9defd990c",
                title: "Catalogue",
                img: "Catalogue.png",
                description: "Card management",
                link: `${process.env.REACT_APP_LINK_URL_CATALOGUE}`,
            },
            {
                id: "Setting-1",
                uuid: "5090fe0f-69fc-9ff9-699d-d9a9defd980a",
                title: "Setting",
                img: "Setting.png",
                description: "Application and device settings",
                link: `${process.env.REACT_APP_LINK_URL_SETTING}`,
            },
            {
                id: "Access-1",
                uuid: "5090fe0f-69fc-9ff9-203b-d9a9defd990c",
                title: "Access",
                img: "Access.png",
                description: "User Management",
                link: `${process.env.REACT_APP_LINK_URL_ACCESS}`,
            },
        ],
        secondContainer: [
            {
                id: "Installer-1",
                uuid: "5075fe0f-852-9ff9-699d-d9a9defd990c",
                title: "Installer",
                img: "Installer.png",
                description: "Application installer",
                link: `${process.env.REACT_APP_LINK_URL_INSTALLER}`,
            },
            // {
            //     id: "Monitoring-1",
            //     uuid: "5090fbbb-951-9ff9-699d-d9a9defd990c",
            //     title: "Monitoring",
            //     img: "Monitoring.png",
            //     description: "Park monitoring",
            //     link: "https://aures.provconnect.net/",
            // },
            {
                id: "Administration-console-1",
                uuid: "4090fe0f-69fc-9ff9-699d-d9a9defd990c",
                title: "Administration console",
                img: "Console.png",
                description: "Account and license management",
                link: `${process.env.REACT_APP_LINK_URL_CONSOLE_ADMINISTRATION}`,
            },
        ],
    };

    useEffect(() => {
        if (isLoading === false) {
            setNotAuthorizationIsLoading(false);
            const isFirstContainer =
                cardsData.firstContainer.filter((el: any) => {
                    return (
                        Object.keys(data).filter(
                            (elem: any) => elem === el.uuid
                        ).length > 0
                    );
                }).length > 0;

            const isSecondContainer =
                cardsData.secondContainer.filter((el: any) => {
                    return (
                        Object.keys(data).filter(
                            (elem: any) => elem === el.uuid
                        ).length > 0
                    );
                }).length > 0;

            const localSecondaryElement: any = [];
            const localMainElement: any = [];

            Object.keys(data).forEach((elem: any) => {
                cardsData.firstContainer
                    .filter((el: any) => {
                        return (
                            Object.keys(data).filter(
                                (elem: any) => elem === el.uuid
                            ).length > 0
                        );
                    })
                    .forEach((element: any) => {
                        if (element.uuid === elem) {
                            localMainElement.push(data[elem]);
                        }
                    });
                cardsData.secondContainer
                    .filter((el: any) => {
                        return (
                            Object.keys(data).filter(
                                (elem: any) => elem === el.uuid
                            ).length > 0
                        );
                    })
                    .forEach((element: any) => {
                        if (element.uuid === elem) {
                            localSecondaryElement.push(data[elem]);
                        }
                    });
            });
            if (isSecondContainer === false && isFirstContainer === false) {
                setNotAuthorization(true);
                setNotAuthorizationIsLoading(false);
            } else if (
                isSecondContainer === false &&
                isFirstContainer === true
            ) {
                localMainElement.forEach((el: any) => {
                    if (el.Visible === false) {
                        setNotAuthorization(true);
                        setNotAuthorizationIsLoading(false);
                    }
                });
            } else if (
                isSecondContainer === true &&
                isFirstContainer === false
            ) {
                localSecondaryElement.forEach((el: any) => {
                    if (el.Visible === false) {
                        setNotAuthorization(true);
                        setNotAuthorizationIsLoading(false);
                    }
                });
            }
        }
        // eslint-disable-next-line
    }, [data, isLoading]);

    return (
        <React.Fragment>
            <div className="title__clz">
                <h1>
                    <span
                        style={{ color: mainColor }}
                        className="etk-title__clz"
                    >
                        {t("The ETK experience")}.
                    </span>
                    <span
                        style={{ color: "#b6b2b1" }}
                        className="opacity-title__clz"
                    >
                        {" "}
                        {t("Do even more with ETK products and services")}
                    </span>
                </h1>
            </div>
            {isLoading === true && notAuthorizationIsLoading === true ? (
                <SpinnerWrapper />
            ) : (
                <React.Fragment>
                    {notAuthorization ? (
                        <NotAuthorizationPage />
                    ) : (
                        <div className="cards">
                            <div className="container__clz d-flex">
                                {cardsData.firstContainer.map((el: any) => {
                                    const indexElement: any = Object.keys(
                                        data
                                    ).filter((elem: any) => elem === el.uuid);
                                    const element: any =
                                        indexElement && data[indexElement[0]];

                                    return element !== undefined &&
                                        element.Consult === true &&
                                        element.Visible === true ? (
                                        <PortalCard
                                            key={el.id}
                                            consult={true}
                                            id={el.id}
                                            title={el.title}
                                            description={el.description}
                                            link={el.link}
                                            img={el.img}
                                        />
                                    ) : element !== undefined &&
                                      element.Consult === false &&
                                      element.Visible === true ? (
                                        <PortalCard
                                            key={el.id}
                                            consult={false}
                                            id={el.id}
                                            title={el.title}
                                            description={el.description}
                                            link={el.link}
                                            img={el.img}
                                        />
                                    ) : null;
                                })}
                            </div>
                            <div className="second-container__clz d-flex">
                                {cardsData.secondContainer.map((el: any) => {
                                    const indexElement: any = Object.keys(
                                        data
                                    ).filter((elem: any) => elem === el.uuid);
                                    const element: any =
                                        indexElement && data[indexElement[0]];
                                    return element !== undefined &&
                                        element.Consult === true &&
                                        element.Visible === true ? (
                                        <PortalCard
                                            key={el.id}
                                            consult={true}
                                            id={el.id}
                                            title={el.title}
                                            description={el.description}
                                            link={el.link}
                                            img={el.img}
                                        />
                                    ) : element !== undefined &&
                                      element.Consult === false &&
                                      element.Visible === true ? (
                                        <PortalCard
                                            key={el.id}
                                            consult={false}
                                            id={el.id}
                                            title={el.title}
                                            description={el.description}
                                            link={el.link}
                                            img={el.img}
                                        />
                                    ) : null;
                                })}
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
